import React from 'react';
import { Row, Col, Card, Container, Table, Alert } from "react-bootstrap";
import { Map, Facebook, TelephoneFill } from 'react-bootstrap-icons';


const MultiLineText = ({ text }) => text.split('\n').map((line, idx) => (
    <span key={idx}>
      {line}
        {idx < text.split('\n').length - 1 && <br/>}
    </span>
));

const ContactPage = ({restaurantsList}) => {

    const schedule = [
        [{day: 'Monday-Thursday', time: '4pm-10pm'}, {day: 'Friday', time: '4pm - 11pm'}, {day: 'Saturday', time: '12pm - 11pm'}, {day: 'Sunday', time: '12pm - 9pm'}],
        [{day: 'Wednesday- Thursday (Lunch time)', time: '11am - 2pm'}, {day: 'Sunday - Thursday (Dinner time)', time: '4pm - 9pm'}, {day: 'Friday And Saturday', time: '4pm - 11pm'}],
    ];

    return (
        <Container className="mt-5">

          <Row>
            <Col xs={ 12 }>
              <div className="container mb-5">
                <Alert variant="warning" className="text-center">
                  <h4>Thanksgiving Closure Notice</h4>
                  <p className="mb-0">
                    Wine4Oysters will be closed on <strong>Thursday, November 28th</strong> and <strong>Friday, November
                    29th</strong> in observance of Thanksgiving.
                    <br/>
                    We will reopen on <strong>Saturday, November 30th</strong>.
                  </p>
                  <p className="mt-2">Thank you for your understanding, and Happy Thanksgiving!</p>
                </Alert>
              </div>
            </Col>
          </Row>
          <Row>
            { restaurantsList.map((restaurant, index) => (
                <Col key={ index } xs={ 12 } md={ 6 }>
                  <Card>
                    <Card.Img variant="top" src={ restaurant.photo.fields.file.url + "?w=400" }/>
                    <Card.Body>
                      <Card.Title>{ restaurant.title }</Card.Title>
                      <Row className="mx-1">
                        <Table striped bordered hover>
                        <tbody>
                                        {schedule[index].map((scheduleItem, idx) =>
                                            <tr key={idx}>
                                                <td>{scheduleItem.day}</td>
                                                <td><MultiLineText text={scheduleItem.time} /></td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </Row>
                                <Row className="mx-1 mt-1">
                                    <Col xs={6}>
                                        <a href={`tel:${restaurant.phone}`}><TelephoneFill size={20} /> {restaurant.phone}</a>
                                    </Col>
                                    <Col xs={6}>
                                        <a href={restaurant.linkFacebook}><Facebook size={20} color="blue"/> Facebook</a>
                                    </Col>
                                </Row>
                                <Row className="mx-1 mt-2">
                                    <Col>
                                        <Map size={19} /> <a
                                        href={`https://maps.google.com/?q=${encodeURIComponent(restaurant.address)}`}
                                        target="_blank" rel="noopener noreferrer">{restaurant.address}</a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <iframe
                            src={`https://www.google.com/maps?q=${encodeURIComponent(
                                restaurant.address
                            )}&output=embed`}
                            width="100%"
                            height="300"
                            style={{border: 0}}
                            allowFullScreen=""
                            loading="lazy"
                            className="my-3"
                            title={`map for ${restaurant.title}`}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default ContactPage;
